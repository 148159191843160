import axios from 'axios';
import { convertKeys } from '../utils/case-converter';
import { csrfToken } from '../utils/csrf-token';
const api = axios.create({ transformRequest: [data => convertKeys(data, 'decamelize'), ...axios.defaults.transformRequest], transformResponse: [...axios.defaults.transformResponse, data => convertKeys(data, 'camelize')], headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'X-CSRF-Token': csrfToken() } });
function create(data) {
    const path = '/api/internal/contacts'; // Asegúrate de que este es el path correcto para tu API
    return api({ method: 'post', url: path, data: { contact: data // Ajusta el nombre del objeto según cómo tu backend espera recibir los datos
        } });
}
export { api, create };
